export default [
  {
    name: 'David Linke',
    company: 'KPMG International',
    role: 'Global Head of Tax & Legal Services',
    image: '/assets/images/contacts/david-linke.jpg',
    link: 'https://home.kpmg/xx/en/home/contacts/l/david-linke.html',
  },
  {
    name: 'Grant Wardell-Johnson',
    company: 'KPMG International',
    role: 'Global Tax Policy Leader and Chair of the Global Tax Policy Leadership Group',
    image: '/assets/images/contacts/grant-wardell-johnson.jpg',
    link: 'https://home.kpmg/xx/en/home/contacts/w/grant-wardell-johnson.html',
  },
  {
    name: 'Melissa Geiger',
    company: 'KPMG International',
    role: 'Global Leader, Strategic Corporates, Tax & Legal',
    image: '/assets/images/contacts/melissa-geiger.jpg',
    link: 'https://home.kpmg/xx/en/home/contacts/g/melissa-geiger.html',
  },
  {
    name: 'Loek Helderman',
    company: 'KPMG International',
    role: 'Global Tax Lead, KPMG ESG ',
    image: '/assets/images/contacts/loek-helderman.jpg',
    link: 'https://home.kpmg/xx/en/home/contacts/h/loek-helderman.html',
  },
  {
    name: 'David Neuenhaus',
    company: 'KPMG International',
    role: 'Global Institutional Investors / Sovereign Wealth and Pension Funds Tax Lead, KPMG in the US',
    image: '/assets/images/contacts/david-neuenhaus.jpg',
    link: 'https://home.kpmg/xx/en/home/contacts/n/david-neuenhaus.html',
  },
  {
    name: 'Robin Walduck',
    company: 'KPMG International',
    role: 'Global Head of Financial Services Tax',
    image: '/assets/images/contacts/robin-walduck.jpg',
    link: 'https://home.kpmg/uk/en/home/contacts/w/robin-walduck.html',
  },
];
