/* eslint-disable no-param-reassign */
import { Module } from 'vuex';

export type State = {
  isMenuOpen: boolean;
  isMobile: boolean;
  isPageLoaded: boolean;
  isDark: boolean;
  currentChapter?: number | string;
};

const SET_STATUS_MENU_OPEN = 'SET_STATUS_MENU_OPEN';
const SET_IS_MOBILE = 'SET_IS_MOBILE';
const SET_IS_PAGE_LOADED = 'SET_IS_PAGE_LOADED';
const SET_IS_DARK = 'SET_IS_DARK';
const SET_CURRENT_CHAPTER = 'SET_CURRENT_CHAPTER';

const module: Module<State, any> = {
  state: {
    isMenuOpen: false,
    isMobile: false,
    isPageLoaded: false,
    isDark: false,
    currentChapter: undefined,
  },

  mutations: {
    [SET_STATUS_MENU_OPEN](newState, value) {
      newState.isMenuOpen = value;
    },
    [SET_IS_MOBILE](newState, value) {
      newState.isMobile = value;
    },
    [SET_IS_PAGE_LOADED](newState, value) {
      newState.isPageLoaded = value;
    },
    [SET_IS_DARK](newState, value) {
      newState.isDark = value;
    },
    [SET_CURRENT_CHAPTER](newState, value) {
      newState.currentChapter = value;
    },
  },

  actions: {
    setStatusMenuOpen({ commit }, value) {
      commit(SET_STATUS_MENU_OPEN, value);
    },
    setIsMobile({ commit }, value) {
      commit(SET_IS_MOBILE, value);
    },
    setIsPageLoaded({ commit }, value) {
      commit(SET_IS_PAGE_LOADED, value);
    },
    setIsDark({ commit }, value) {
      commit(SET_IS_DARK, value);
    },
    setCurrentChapter({ commit }, value) {
      commit(SET_CURRENT_CHAPTER, value);
    },
  },

  // getters: {
  //   getStatusMenuOpen: s => () => s.isMenuOpen,
  // }
};

export default module;
