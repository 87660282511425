import { Chapter } from '@/types/chapter';

type ChapterData = {
  theme?: 'light' | 'dark';
  title: string;
  markup: string;
  prediction: string;
  data: Chapter[];
};

const chapter1: ChapterData = {
  theme: 'light',
  title: 'The 2030 citizen',
  markup: `
    <p class="u-text-large" data-animation="fadeUp" data-delay="0.4">
      Citizens of 2030 are much more
      aware of the importance of tax
      than people of earlier eras. The tax
      transparency movement that began
      in the 2010s fed a growing appetite
      for tax fairness and transparency.
      Then pandemic-related emergency
      spending and the ensuing financial
      toll in the 2020s highlighted the
      importance of governments’ fiscal
      responsibility and prudence.
    </p>
    <p data-animation="fadeUp" data-delay="0.2">
    Popular attitudes now hold that
    corporations have obligations
    beyond their bottom lines to
    make positive contributions to
    the communities they operate in.
    Citizens of 2030 benefit from the
    way this shift has drawn more focus
    to the social contract between
    business and government.
    </p>
    <p>By emphasizing a company’s
    license to operate, governments
    have set new expectations for
    businesses to step in where public
    services were inadequate. For
    example, as the pandemic made
    work-from-anywhere practices
    routine and the global war for talent
    intensified, many fiscally challenged
    governments have put societal
    pressure on businesses to do more
    to support education and training
    with sponsorships, bursaries and
    other subsidies for learning.
    </p>
    <p>
    As businesses accepted their side
    of the social bargain, they also
    became more forceful in making
    sure governments fulfill their side
    of the bargain. As a result, citizens
    benefit from the more active interest
    and involvement of businesses in
    making tax policies that work.
    </p>
    <p>
    Businesses are more vocal in
    ensuring governments spend taxes
    wisely, demonstrate clear policy
    outcomes, and set a stable playing
    field for economic activity and
    inclusive growth.
    </p>
    <p>
    In the wake of COVID-19, improved
health and wellness has become
one of the social contract’s top
priority outcomes. Governments
and companies now prize the role
of health and wellness in creating
prosperous economies and share
responsibility for promoting it across
their populations.
    </p>
    <p>
    Many governments foster business
    support for innovation around
    health and wellness, for example,
    with tax concessions or grants for
    research and experimentation in
    areas ranging from pharmaceutical
    
    development to financial services to
    health care.
    </p>
    <p>
    Meanwhile, citizens find it much
easier to deal with their taxes than
it used to be. Advances in artificial
intelligence and data analytics are
allowing tax authorities’ systems
to prepare most straightforward
income tax returns automatically,
with taxpayers simply obliged to
review and approve them.
    </p>
    <p>
    As technology allows compliance to
happen in real time, tax authorities
no longer focus on verifying tax
filings after the fact. They are now
more involved in proactively helping
taxpayers avoid mistakes and get tax
compliance right the first time.
    </p>
    <p>
    Many governments have
streamlined their tax, benefit and
other services within a single
organization. This means the
citizens of 2030 have a one-stop
shop to access all tax compliance
and social welfare programs. It also
gives governments new powers to
integrate tax and welfare data for
policy development, so their citizens
enjoy better-designed programs and
services.
    </p>
  `,
  prediction: `
    <span data-animation="fadeUp">4,021,928</span>
    <br/>
    <span data-animation="fadeUp">minutes until</span>
    <br/>
    <span data-animation="fadeUp">2030</span>
  `,
  data: [
    [
      {
        type: 'person',
        key: 'richard-sumner',
      },
      {
        type: 'prediction',
        markup: `
          <p>Governments have stepped up direct
          support for their citizens’ health and
          wellness.</p>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <p>
          Tax administrations have morphed into mega-
          agencies in charge of all financial dealings
          between governments and their citizens.
          </p>
        `,
      },
      {
        type: 'person',
        key: 'jeremy-hirschhorn',
      },
    ],
    [
      {
        type: 'person',
        key: 'nazrien-kader',
      },
      {
        type: 'person',
        key: 'allen-friedman',
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <p>Tax policies incent the private sector to fill
          gaps where education, training and other
          government services are lacking.</p>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <p>Citizens find their taxes easier to manage as
          tax authorities use data to help them avoid
          mistakes and get compliance right.</p>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <p>Businesses are bolder in holding
          governments to account as equal partners
          in the social contract.</p>
        `,
      },
    ],
  ],
};

const chapter2: ChapterData = {
  theme: 'dark',
  title: 'Globalization and geopolitics',
  markup: `
    <p class="u-text-large" data-animation="fadeUp" data-delay="0.4">
      While there are forces at work leading to fragmentation in areas like trade, supply chains and energy security, globalization remains a success as far as international tax co-operation is concerned.
    </p>
    <p data-animation="fadeUp" data-delay="0.2">
      In the 2020s, a number of forces converged to move geopolitics from adversarial ‘wars of maneuver’ to ‘wars of position’ to win power by gaining influence. Success in the new arena requires the parties involved to be more transparent, collaborative and open to a diversity of ideas.
    </p>
    <p data-animation="fadeUp" data-delay="0.2">This innovative approach was key to the OECD’s ability to forge breakthroughs in tax co-operation among over 135 countries. From automatic exchange of information to the multilateral instrument, common approach and, most importantly, the agreement on global tax regulation via Pillars One and Two, the OECD was able to get all parties to agree on minimum standards that each party must meet, while giving them room to tailor the rules for their own jurisdiction’s needs.</p>
    <p data-animation="fadeUp" data-delay="0.4">By maintaining a forum that brings the world’s tax administrations together regularly, the OECD helps keep the playing field level and opens more tax certainty for today’s international companies. The tax policy solutions being put in place now are vastly improved by the democratic consultation and engagement that goes into them.</p>
    <p data-animation="fadeUp" data-delay="0.6">As a result of this international co-operation, jurisdictions have largely recovered their ability to tax large multinationals. Aggressive tax planning and double nontaxation are things of the past, and there is a will to improve taxation on the part of taxpayers and tax administrations alike.</p>
    <p data-animation="fadeUp" data-delay="0.8">Of course, the global tax regime is still new, and lots of wrinkles still need to be ironed out. Unintended consequences abound, and so do cases of double taxation. Tax authorities and tax functions have their hands full with a new wave of tax disputes — but in a different environment than in the past. New, highly effective cross-border tax dispute mechanisms were agreed on as part of the OECDdriven package of tax proposals, and international tax disputes are now generally easier and faster to resolve.</p>
    <p data-animation="fadeUp" data-delay="1.0">Nevertheless, as governments and businesses work through the kinks, the current surge in tax uncertainty and tax disputes seems inevitable. A variety of measures, including co-operative compliance arrangements and mediation programs, were implemented to help businesses to understand and predict the tax effects of their international activity.</p>
    <p data-animation="fadeUp" data-delay="1.2">Meanwhile, developing countries such as Jamaica are taking new approaches to tax policy — using it to help restructure their economies by better mobilizing domestic resources and improving how they manage public funds. Since nations can no longer use headline tax rates to compete for foreign business and investment, tax incentives are playing a bigger role in location decisions. Many countries are also taking steps to eliminate impediments by consolidating and streamlining tax laws, getting rid of red tape, and improving interactions with the tax system in general. </p>
  `,
  prediction: `
    <ul>
      <li data-animation="fadeUp">1. this</li>
      <li data-animation="fadeUp">2. that</li>
      <li data-animation="fadeUp">3. something</li>
    </ul>
  `,
  data: [
    [
      {
        type: 'person',
        key: 'pascal-saint-amans',
      },
      {
        type: 'prediction',
        markup: `
          <p>Geopolitics have shifted from ‘wars of maneuver’ to ‘wars of position,’ requiring more transparency, collaboration and diversity of ideas on all sides.</p>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <p>
          As the complex rules under BEPS 2.0 are put in place, tax certainty is more important than ever.
          </p>
        `,
      },
      {
        type: 'person',
        key: 'neal-lawson',
      },
    ],
    [
      {
        type: 'person',
        key: 'marlene-nembhard-parker',
      },
      {
        type: '',
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <p>Governments have recovered their ability to tax large multinationals, largely because of breakthroughs in tax cooperation facilitated by the OECD.</p>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <p>Developing countries are relying more on tax policy to help restructure their economies, mobilize domestic resources and improve the management of public funds.</p>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
        <p>Aggressive tax planning and double non-taxation are things of the past.</p>
        `,
      },
    ],
  ],
};

const chapter3: ChapterData = {
  theme: 'light',
  title: 'Data and transparency',
  markup: `
    <p class="u-text-large" data-animation="fadeUp" data-delay="0.4">
    The past 20 years saw a sea change in social attitudes toward corporate responsibility and tax responsibility alike. As non-governmental organizations, the media and activist investors increasingly called out corporate misbehavior, the amount of tax that companies paid, and where they paid it, drew more and more attention. The risk to corporate reputations mounted, especially as many jurisdictions made country-by- country tax reports public and other tax transparency measures came onstream.
    </p>
    <p data-animation="fadeUp" data-delay="0.2">
    Today stakeholders of international companies inside and outside acknowledge that corporations have obligations beyond their bottom lines to make positive contributions to the communities they operate in.They also recognize that transparency makes people more accountable and that data drives behavior. Now, environmental, social and governance priorities are embedded in organizational cultures. Corporate tax strategies are geared toward paying a fair share of tax, giving back and doing the right thing when it comes to tax incentives.
    </p>
    <p data-animation="fadeUp" data-delay="0.4">Tax compliance used to consume huge amounts of time and resources, and the risks of errors and omissions were hard to control. Now international tax rules are converging around common data sets for both income and indirect taxes, and data-driven tax compliance management systems are widespread. Tax teams can download tax-sensitized data from their general ledger into completed tax returns within seconds.This means tax teams spend only minimal amounts of time on routine compliance and more time supporting the business.</p>
    <p data-animation="fadeUp" data-delay="0.6">Tax audits have been changed by technology as well.Today’s tax authorities have gotten very good at risk-assessing taxpayers by analyzing the reams of data they gain from sources like automated tax filings, country-by-country reports and benchmarking information. They apply analytics to target their attention and develop specific issues. Instead of a flurry of queries, tax auditors are more likely to ask focused questions on
    potential problems that they have detected in the data.</p>
    <p data-animation="fadeUp" data-delay="0.8">These new capabilities are vastly increasing the powers of tax authorities to enforce compliance and raise collections. Good governance is needed to ensure they use these powers as intended. Tax administration processes need to strike the right balance between collecting the right amount of tax under the law versus a target of
    tax that the government wants to collect to finance its agenda.</p>
    <p data-animation="fadeUp" data-delay="1.0">In this environment, tax functions need to be just as good at managing and analyzing data as the tax authorities. Now that tax functions have been equipped with company- wide systems for handling tax data, as well as the financial and scenario analysis skills to understand it, they can do their own stress testing
    to find potential trouble spots, document facts and positions — and often pre-empt tax authority questions before they’re asked.</p>
  `,
  prediction: `
    <p></p>
  `,
  data: [
    [
      {
        type: 'person',
        key: 'greg-elliott',
      },
      {
        type: 'prediction',
        markup: `
          <p>More stakeholders recognize that transparency makes people more accountable and that data drives behavior.</p>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <p>
          Data and analytics have vastly increased tax authorities’ powers to enforce compliance and raise collections.
          </p>
        `,
      },
      {
        type: 'person',
        key: 'janine-juggins',
      },
    ],
    [
      {
        type: 'person',
        key: 'paramjit-matharu',
      },
      {
        type: '',
      },
    ],
    [
      {
        type: 'person',
        key: 'david-gordon',
      },
      {
        type: '',
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <span>
            Collaboration among tax authorities has brought more transparency and consistency to income and indirect tax rules.
          </span>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <span>
            Good governance is needed to ensure tax authorities use these powers as intended.
          </span>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <span>
          Corporate income tax systems have moved toward a globally common, data-driven approach.
          </span>
        `,
      },
    ],
  ],
};

const chapter4: ChapterData = {
  theme: 'dark',
  title: 'Promoting innovation',
  markup: `
    <p class="u-text-large" data-animation="fadeUp" data-delay="0.4">
    The past decade saw rapid development among the emerging economies in Southeast Asia and sub-Saharan Africa. The telecommunications sector has been central to this transformation, with service providers and their supply chains innovating
    new technologies to increase communication, provide better access to resources, and deliver new services.
    </p>
    <p data-animation="fadeUp" data-delay="0.2">
      Now that a reliable internet connection is considered essential, luxury taxes and other tax deterrents have largely disappeared, making mobile technology much cheaper
      for lower-income citizens to buy and use. In turn, these citizens now have more open access to education, training and job opportunities.
    </p>
    <p data-animation="fadeUp" data-delay="0.4">
      Meanwhile, innovations in fintech are bringing greater financial inclusion to lower-income individuals and smaller businesses around
      the world. In developing countries, new types of financial products, credit lines and short-term loans
      are making financial services more affordable and accessible.</p>
    <p data-animation="fadeUp" data-delay="0.6">
      This support has been especially valuable in helping smaller, entrepreneurial businesses scale up, resulting in stronger economies overall. It has also been instrumental in bringing many previously unbanked smaller businesses into the formal economy.
    </p>
    <p data-animation="fadeUp" data-delay="0.8">
      Having seen firsthand how technology can lift incomes and living standards, developing countries are now seeking to further develop and expand their jurisdiction’s capabilities.
    </p>
    <p data-animation="fadeUp" data-delay="1.0">
      As part of this, governments now recognize the importance of attracting patient capital — that is, long-term investments in start- up and smaller businesses that give scientists, engineers and entrepreneurs the resources they need to develop opportunities and deliver sustainable long-term growth.
    </p>
    <p data-animation="fadeUp" data-delay="1.2">
      In addition, tax breaks promoting more traditional investments in infrastructure are increasingly supplemented with new tax measures to facilitate innovation and research.
    </p>
    <p data-animation="fadeUp" data-delay="1.4">
      Additional measures aim to help recruit and retain skilled workers who might otherwise be tempted by prospects offshore. Tax policies to deter any brain drain are now common across the developing world, with incentives for people to stay where they are and invest their time, energy and money into improving their own economies.
    </p>
    <p data-animation="fadeUp" data-delay="1.6">
      As a result, many of these countries are set to become net innovation exporters themselves, raising the living standards and economic prospects of their citizens.
    </p>
  `,
  prediction: `
    <ul>
      <li data-animation="fadeUp">1. this</li>
      <li data-animation="fadeUp">2. that</li>
      <li data-animation="fadeUp">3. something</li>
    </ul>
  `,
  data: [
    [
      {
        type: 'person',
        key: 'gareth-harrison',
      },
      {
        type: 'prediction',
        markup: `
          <p>Innovations in telecommunications, fintech and other technologies are accelerating economic development in emerging markets.</p>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <p>
            Governments recognize the importance of patient capital investments for sustainable long-term growth.
          </p>
        `,
      },
      {
        type: 'person',
        key: 'gina-maio',
      },
    ],
    [
      {
        type: 'person',
        key: 'adriana-gonzalez',
      },
      {
        type: 'prediction',
        layout: 'long',
        markup: `
          <span>
            Luxury taxes on smart phones and other tax deterrents to connectivity have disappeared, making mobile technology affordable for people of all income levels.
          </span>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <span>
            Innovations in fintech are creating more financial inclusion and supporting entrepreneurial growth.
          </span>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <span>
            Developing countries are enriching their tax incentives for innovation, research and skilled employment, and becoming innovation exporters themselves.
          </span>
        `,
      },
    ],
  ],
};

const chapter5: ChapterData = {
  theme: 'light',
  title: 'Building a sustainable world',
  markup: `
    <p class="u-text-large" data-animation="fadeUp" data-delay="0.4">
      In 2030, environmental, social and governance (ESG) concerns have moved from the fringes to the center of corporate cultures and strategies, especially when it comes to tax. Virtually every company has a clear ESG policy in place, and many boards communicate them broadly, including their frameworks for tax strategy and governance.
    </p>
    <p data-animation="fadeUp" data-delay="0.2">
      For large strategic investors, ESG policies are now especially important. Industry leaders recognize that the performance of real estate assets is tied to how well asset managers navigate ESG challenges. Now ESG and return
      on investment considerations are aligned to the point where following an ESG program usually leads to better returns.    
    </p>
    <p data-animation="fadeUp" data-delay="0.4">
      Now that a global corporate minimum income tax is in place, tax competition no longer exists to drive businesses to seek the lowest possible rate. This has eliminated the tension for asset managers between
      the need to manage ESG risks and the desire to maximize investment returns. Instead, tax planning has evolved toward accessing tax
      and non-tax incentives offered by governments to encourage green investments and innovation.
    </p>
    <p data-animation="fadeUp" data-delay="0.6">
      Many of these incentives are intended to develop more circular economies. For decades, people had realized the need to shift
      the tax burden away from labor and onto natural resources and pollution, but it wasn’t until events of the 2020s that business began developing interest in creating more sustainable, circular business models.
    </p>
    <p data-animation="fadeUp" data-delay="0.8">
      Following on the Green Deal, and similar initiatives, almost every nation developed an integrated
      road map to establish a sustainable, circular economy, investing in the skills and sectors that needed it most. Tax policy was central to these plans, and a hybrid carrot-and-stick approach has proven the best way to
      get industries of all types to mitigate damage and change the way they do business.
    </p>
    <p data-animation="fadeUp" data-delay="1.0">
      Unlike linear business activities, circular business models are far more intensive in terms of the amount of labor, knowledge and energy required. Moving off labor taxes reduced the cost of labor and thus improved the business case for work on renewable resources, retrofitting homes, repairing appliances and other circular activities.
    </p>
    <p data-animation="fadeUp" data-delay="1.2">
      The move away from taxing labor had benefits beyond the private sector, slashing hiring costs in the public sector across the board, from health care and education to law enforcement to the judiciary. We now enjoy safer, more inclusive economies as a result, with greater wealth and well-being for all.
    </p>
  `,
  prediction: `
  `,
  data: [
    [
      {
        type: 'person',
        key: 'femke-groothuis',
      },
      {
        type: 'prediction',
        markup: `
          <p>ESG and return on investment considerations have aligned so that ESG programs usually produce better returns.</p>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <p>
            Every nation has developed an integrated road map to establish a sustainable, circular economy.
          </p>
        `,
      },
      {
        type: 'person',
        key: 'kaushal-tikku',
      },
    ],
    [
      {
        type: 'person',
        key: 'heather-crowder',
      },
      {
        type: '',
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <span>
            Safer, more inclusive economies are resulting, with greater wealth and well-being for all.
          </span>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <span>
            The end of tax competition has shifted corporate tax planning toward accessing incentives.
          </span>
        `,
      },
    ],
    [
      {
        type: 'prediction',
        markup: `
          <span>
            Hybrid carrot and stick approaches to tax have proven the best way to get companies to mitigate damage and change the way they do business.
          </span>
        `,
      },
    ],
  ],
};

export default [chapter1, chapter2, chapter3, chapter4, chapter5] as ChapterData[];
