import { Module } from 'vuex';
import type Scroll from '@/widgets/Scroll';
import type Cursor from '@/widgets/Cursor';

interface AddProps {
  instance: any;
  instanceKey: 'cursor';
}

export type State = {
  cursor: InstanceType<typeof Cursor> | null;
  scroll: InstanceType<typeof Scroll> | null;
};

const module: Module<State, any> = {
  namespaced: true,
  state: {
    cursor: null,
    scroll: null,
  },
  mutations: {
    add(state, { instance, instanceKey }: AddProps) {
      const newState = state;
      newState[instanceKey] = instance;
    },
  },
};

export default module;
