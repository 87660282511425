import { onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { debounce } from '@/utils/utils';

const useInit = (preventScroll?: boolean) => {
  const store = useStore();

  const initScroll = () => {
    if (store.state.widgets.scroll) {
      store.state.widgets.scroll.init();
    }
  };

  const resize = debounce(initScroll, 500);

  onMounted(() => {
    if (store.state.widgets.cursor) {
      store.state.widgets.cursor.reset();
    }
    if (store.state.widgets.scroll && !preventScroll) {
      store.state.widgets.scroll.activate(document.documentElement);
    }
    window.addEventListener('resize', resize);
  });

  onUnmounted(() => {
    if (store.state.widgets.scroll) {
      store.state.widgets.scroll.destroy();
    }

    window.removeEventListener('resize', resize);
  });
};

export default useInit;
