interface SetItem {
  key?: string;
  value: string;
  expired?: any;
  path?: string;
  domain?: string;
  secure?: boolean;
}

interface RemoveItem {
  key: string;
  path?: string;
  domain?: string;
}

type Debounce = (
  func: (args?: any) => any,
  wait: number,
  immediate?: boolean,
) => () => void;

export function padZero(str: string, len: number): string {
  const modifiedLen = len || 2;
  const zeros = new Array(modifiedLen).join('0');
  return (zeros + str).slice(-modifiedLen);
}

export function invertColor(hex: string): string {
  let h = hex;

  if (h.indexOf('#') === 0) {
    h = h.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (h.length === 3) {
    h = h[0] + h[0] + h[1] + h[1] + h[2] + h[2];
  }
  if (h.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  const r = (255 - parseInt(h.slice(0, 2), 16)).toString(16);
  const g = (255 - parseInt(h.slice(2, 4), 16)).toString(16);
  const b = (255 - parseInt(h.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  // @ts-ignore
  return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
}

export function hexToRgb(hex: string): { r: number, g: number, b: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
}

export function getUrlVars() {
  const vars = [];
  let hash: string[];
  const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

  for (let i = 0; i < hashes.length; i += 1) {
    hash = hashes[i].split('=');
    vars.push(hash[0]);
    // eslint-disable-next-line prefer-destructuring
    vars[Number(hash[0])] = hash[1];
  }
  return vars;
}

export const readCookie = {
  getItem: (key?: string): string | null => {
    if (!key) { return null; }
    /* eslint-disable-next-line */
    return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(key).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;
  },

  setItem: ({
    key,
    value,
    expired,
    path,
    domain,
    secure,
  }: SetItem): boolean => {
    if (!key || /^(?:expires|max-age|path|domain|secure)$/i.test(key)) { return false; }

    let sExpires = '';

    if (expired) {
      switch (expired.constructor) {
        case Number:
          sExpires = expired === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : `; max-age=${expired}`;
          break;
        case String:
          sExpires = `; expires=${expired}`;
          break;
        case Date:
          sExpires = `; expires=${expired.toUTCString()}`;
          break;
        default:
          sExpires = '';
      }
    }

    document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)}${sExpires}${(domain ? `; domain=${domain}` : '')}${(path ? `; path=${path}` : '')}${(secure ? '; secure' : '')}`;
    return true;
  },

  removeItem: ({ key, path, domain }: RemoveItem): boolean => {
    if (!readCookie.hasItem(key)) { return false; }
    document.cookie = `${encodeURIComponent(key)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${(domain ? `; domain=${domain}` : '')}${(path ? `; path=${path}` : '')}`;
    return true;
  },

  hasItem: (key?: string): boolean => {
    if (!key) { return false; }
    return (new RegExp(`(?:^|;\\s*)${encodeURIComponent(key).replace(/[-.+*]/g, '\\$&')}\\s*\\=`)).test(document.cookie);
  },

  keys: (): any[] => {
    // eslint-disable-next-line no-useless-backreference
    const aKeys = document.cookie.replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '').split(/\s*(?:=[^;]*)?;\s*/);
    for (let nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx += 1) {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
    }
    return aKeys;
  },
};

export const debounce: Debounce = (func, wait, immediate = false) => {
  let timeout: number | null;

  return (...args: any) => {
    const later = () => {
      timeout = null;
      // @ts-ignore
      if (!immediate) func.apply(this, args);
    };

    const callNow = immediate && !timeout;
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);
    // @ts-ignore
    if (callNow) func.apply(this, args);
  };
};

export const loadVideoScript = () => new Promise(resolve => {
  const element = document.querySelector<HTMLScriptElement>('#video-script');
  if (element) {
    element.parentElement?.removeChild(element);
  }

  const scriptTag = document.createElement('script');
  scriptTag.id = 'video-script';
  scriptTag.src = 'https://players.brightcove.net/3755095886001/default_default/index.min.js';
  document.body.appendChild(scriptTag);
  scriptTag.onload = () => resolve(true);
});

/**
* Get data-attribute matching an element and attribute suffix
*
* @param {HTMLElement} el [dom element]
* @param {String} attr [data-attr suffix]
*/
export function getAttribute(el: HTMLElement, attr: string) {
  return el.getAttribute(`data-${attr}`);
}

/**
* Get data-attribute matching an element and attribute suffix
*
* @param {HTMLElement} el [dom element]
* @param {String} attr [data-attr suffix]
* @return {Object} contains width and height properties
*/
export function setAttribute(el: HTMLElement, attr: string, val: string | boolean | null = '') {
  if (el.getAttribute(`data-${attr}`) !== val) el.setAttribute(`data-${attr}`, String(val));
}
