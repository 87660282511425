import { createStore, useStore as baseUseStore } from 'vuex';
import posts, { State as PostState } from './posts';
import status, { State as StatusState } from './status';
import widgets, { State as WidgetsState } from './widgets';

export type State = {
  posts: PostState;
  status: StatusState;
  widgets: WidgetsState;
};

// export const key: InjectionKey<Store<State>> = Symbol('key');

export default createStore<State>({
  modules: {
    posts,
    status,
    widgets,
  },
});

export function useStore() {
  return baseUseStore<State>();
}
