import { defineComponent } from 'vue';
import { gsap } from 'gsap';

export default defineComponent({

  transitions: {
    css: false,
    mode: 'out-in',
    async leave(el: HTMLElement, done: () => void) {
      await gsap.to(el, {
        opacity: 0, y: -20, duration: 0.4, ease: 'back',
      });
      done();
    },
    beforeEnter(el: HTMLElement) {
      gsap.set(el, { opacity: 0, y: 20 });
    },

    async enter(el: HTMLElement, done: () => void) {
      const isDark = !!(el.dataset && el.dataset.dark);
      // @ts-ignore
      const { $store } = this;

      // TODO: shouldn't change the status in this component
      if (isDark) {
        $store.dispatch('status/setIsDark', true);
      } else if ($store.state.status.isDark) {
        $store.dispatch('status/setIsDark', false);
      }

      await gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.4,
        ease: 'back',
      });

      gsap.set(el, { clearProps: 'all' });

      done();
    },
  },
});
