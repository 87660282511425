const trackingID = process.env.TRACKING_ID;

/**
 * Initialize Google Analytics if analytics id exists and environment is
 * production
 */
export function initGA(): void {
  if (trackingID && window.gtag) {
    window.gtag('config', trackingID, {
      page_path: window.location.pathname,
    });
  }
}

/**
 * Trigger analytics event within google analytics through react-ga
 * @param  {Object} eventData - Data associated with the event.
 */
export function triggerAnalyticsEvent(): void {
  // console.log('ga event');
  // // if (trackingID && process.env.NODE_ENV === 'production') {
  // if (trackingID) {
  //   ReactGA.event(eventData);
  // } else {
  //   console.debug('Analytics Event:', eventData); // eslint-disable-line no-console
  // }
}

/**
 * Set user auth data within Google Analytics
 * @param {Object} auth - Authentication data
 * @param {String} auth.uid - User's id
 */
export function setGAUser(auth: { uid: number, role?: string }): void {
  if (auth && window.gtag) {
    if (auth.uid || auth.role) {
      // window.gtag('set', 'user_properties', {
      //   user_id: auth.uid,
      //   // delegate_id: auth.uid,
      //   Role: auth.role,
      // });
    }
  }
}

/**
 * Track route update within Google Analytics
 */
export function trackRouteUpdate(label?: string): void {
  if (window.gtag) {
    window.gtag('event', 'click_link', {
      event_category: 'navigation',
      event_label: label,
    });
  }
}

export default { initGA, triggerAnalyticsEvent, trackRouteUpdate };
