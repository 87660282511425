import { createApp } from 'vue';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import App from './App.vue';
import router from './router';
import store from './store';

gsap.registerPlugin(ScrollToPlugin);

createApp(App)
  .use(store)
  .use(router)
  // .use(Fragment.Plugin)
  .mount('#vue-app');
